import BoardHeader from './components/BoardHeader';
import KanbanBoard from './components/KanbanBoard';
import useClientsSheets from './hooks/useClientSheets';
import { SHEET_STATES } from './constants';
import styled from 'styled-components';

function Board() {
  const { currentDate, currentGroup, currentGroupSheets } = useClientsSheets();
  const sheetsToValidate = currentGroupSheets.filter(
    (item) => item.state === SHEET_STATES.Sent,
  );

  return (
    <>
      <BoardHeader
        currentDate={currentDate}
        currentGroup={currentGroup}
        sheetsToValidate={sheetsToValidate.length}
      />
      <BoardContainer>
        {currentGroupSheets.length === 0 ? (
          '⏳ Cargando tablero ...'
        ) : (
          <KanbanBoard cardsList={currentGroupSheets} />
        )}
      </BoardContainer>
    </>
  );
}

const BoardContainer = styled.div`
  height: calc(100% - 128px);
  overflow-y: auto;
  padding-bottom: 60px;
`;

export default Board;
