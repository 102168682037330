import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';
import routes from 'shared/constants/routes';

// Components
import SidebarTemplate from 'shared/components/templates/SidebarTemplate';
import Login from 'modules/login/Login';
import Board from 'modules/board/Board';
import Sheets from 'modules/sheets/Sheets';
import Clients from 'modules/clients/Clients';
import Loader from 'shared/components/pages/Loader';
import Sidebar from 'app/components/Sidebar';
import { useAuthStateChanged } from 'app/hooks/useAuthStateChanged';

export default function App() {
  const userId = useAuthStateChanged();

  const PrivateRoute = (routeProps: RouteProps) => {
    return userId ? <Route {...routeProps} /> : <Redirect to={routes.LOGIN} />;
  };

  const PublicRoute = (routeProps: RouteProps) => {
    return userId ? <Redirect to={routes.HOME} /> : <Route {...routeProps} />;
  };

  return userId === undefined ? (
    <Loader />
  ) : (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path={routes.LOGIN} component={Login} />
        <SidebarTemplate>
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="container">
            <Switch>
              <PrivateRoute
                exact
                path={routes.HOME}
                component={() => <Redirect to={routes.BOARD} />}
              />
              <PrivateRoute exact path={routes.BOARD} component={Board} />
              <PrivateRoute exact path={routes.CLIENTS} component={Clients} />
              <PrivateRoute exact path={routes.SHEETS} component={Sheets} />

              {/* 404 fallback to routes.HOME */}
              <PrivateRoute
                path="*"
                component={() => <Redirect to={routes.HOME} />}
              />
            </Switch>
          </div>
        </SidebarTemplate>
      </Switch>
    </BrowserRouter>
  );
}
