import { useEffect, useState } from 'react';
import { fetchActiveClientsGroupedByGroup } from 'services/actions/clients';
import { fetchSheetsByClientAndDateRange } from 'services/actions/sheets';
import { TKanbanCard } from '../components/KanbanColumn';
import { SHEET_STATES } from '../constants';
import { getPrevMonthDate } from 'shared/utils/misc';
import { DEFAULT_LOCATION } from 'shared/constants/clients';
import { ClientSheetI, ClientI } from 'types';

// --- HELPERS --- //
const CURRENT_DATE = new Date();

function getGroupFromDate(date: number) {
  return date > 30 ? 3 : Math.ceil(date / 10);
}

function getSheetState(sheet: ClientSheetI, isMissing?: boolean) {
  if (isMissing) {
    return SHEET_STATES.NotCreated;
  } else if (sheet.isValidated) {
    return SHEET_STATES.Validated;
  } else if (sheet.isSent) {
    return SHEET_STATES.Sent;
  } else {
    return SHEET_STATES.Editing;
  }
}

function getFormattedDataSheet(
  sheet: ClientSheetI,
  client: ClientI,
  isMissing?: boolean,
): TKanbanCard {
  const { id, month, year } = sheet;
  return {
    id,
    title: `${client.displayName || client.name} ${client.isDummy ? ' *' : ''}`,
    month,
    year,
    state: getSheetState(sheet, isMissing),
    isPastDue: new Date(year, month).getTime() < getPrevMonthDate().getTime(),
    clientId: client.id,
  };
}

function getSheetsPromiseByClient(client: ClientI) {
  return fetchSheetsByClientAndDateRange(client.id, {
    startDate: getPrevMonthDate(client.activationDate),
    endDate: CURRENT_DATE,
  }).then(function getFormattedDataSheets(response): TKanbanCard[] {
    return response.map((item) => getFormattedDataSheet(item, client));
  });
}

function getMissingSheets(client: ClientI, currentGroupSheets: TKanbanCard[]) {
  const missingPrevMonthSheets: TKanbanCard[] = [];
  const prevMonthDate = getPrevMonthDate();
  const prevMonthSheet = currentGroupSheets.find(function isPrevMonthSheet(
    sheet,
  ) {
    return (
      sheet.clientId === client.id &&
      prevMonthDate.getTime() === new Date(sheet.year, sheet.month).getTime()
    );
  });
  if (prevMonthSheet === undefined) {
    missingPrevMonthSheets.push(
      getFormattedDataSheet(
        {
          id: `${client.id}-${prevMonthDate.getMonth()}`,
          month: prevMonthDate.getMonth(),
          year: prevMonthDate.getFullYear(),
        },
        client,
        true,
      ),
    );
  }
  return missingPrevMonthSheets;
}

// ---- HOOK ---- //

function useClientsSheets() {
  const currentGroup = getGroupFromDate(CURRENT_DATE.getDate());
  const [currentGroupClients, setCurrentGroupClients] =
    useState<ClientI[] | undefined | null>();
  const [currentGroupSheets, setCurrentGroupSheets] = useState<TKanbanCard[]>(
    [],
  );
  const [missingSheets, setMissingSheets] = useState<TKanbanCard[]>([]);

  // 1. get the clients
  useEffect(() => {
    fetchActiveClientsGroupedByGroup().then((response) => {
      const groupClientsFromBogota = response[currentGroup - 1].filter(
        (item: ClientI) => item.location === DEFAULT_LOCATION,
      );
      setCurrentGroupClients(groupClientsFromBogota);
    });
  }, [currentGroup]);

  // 2. get the sheets of those clients
  useEffect(() => {
    const sheetsPromises = currentGroupClients?.map(getSheetsPromiseByClient);
    if (sheetsPromises) {
      Promise.all(sheetsPromises).then((response: TKanbanCard[][]) => {
        setCurrentGroupSheets(response.flat());
      });
    }
  }, [currentGroupClients]);

  // 3. get the missing sheets
  useEffect(() => {
    const missingPrevMonthSheets: TKanbanCard[] = [];
    currentGroupClients?.forEach(function populateMissingSheets(client) {
      missingPrevMonthSheets.push(
        ...getMissingSheets(client, currentGroupSheets),
      );
    });
    setMissingSheets(missingPrevMonthSheets);
  }, [currentGroupClients, currentGroupSheets]);

  return {
    currentDate: CURRENT_DATE.getDate(),
    currentGroup,
    currentGroupSheets: [...currentGroupSheets, ...missingSheets],
    currentGroupClients,
  };
}

export default useClientsSheets;
