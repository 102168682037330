import MONTHS from 'shared/constants/months';

export function getFullDateString(day: number, month: number, year: number) {
  return [
    String(day).padStart(2, '0'),
    String(month + 1).padStart(2, '0'),
    year,
  ].join('/');
}

export function getInvoiceString(month: number, year: number, index: number) {
  return (
    year + String(month + 1).padStart(2, '0') + String(index).padStart(3, '0')
  );
}

export function getFileName(authorName: string, month: number, year: number) {
  return [authorName, MONTHS[month], year].filter((item) => item).join(' ');
}

const getStringWithFirstUppercase = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export function getCapitalizedString(string: string) {
  return string
    .split(' ')
    .map((word) => getStringWithFirstUppercase(word))
    .join(' ');
}
