import styled from 'styled-components';

const SidebarTemplate = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;

  .sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    flex-grow: 1;
    height: 95%;
    min-width: 200px;
    width: 15%;
    max-width: 350px;
  }

  .container {
    background-color: ${(props) => props.theme.palette.neutral['white']};
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    flex-direction: column-reverse;

    .sidebar {
      flex-direction: row;
      max-width: 100vw;
      width: 100vw;
      height: auto;
      padding: 5px 10px;
    }

    .container {
      max-width: 100vw;
      width: 100vw;
    }
  }
`;

export default SidebarTemplate;
