import { CupsJsonI } from 'types';

export function* getInvoiceGen(i: number, year: number, month: number) {
  while (true) {
    yield year.toString() +
      month.toString().padStart(2, '0') +
      (i++).toString().padStart(3, '0');
  }
}

export function isConsult(procedureCode: number, cups: CupsJsonI) {
  const procedureInfo = cups[procedureCode];
  // the code exists on cups list and is 'AC' type which means it's a consult
  if (procedureInfo && procedureInfo['cupsTipoAtencion'] === 'AC') {
    return true;
  }
  // the code doesn't exists on cups list but appears to be a consult
  // consults tend to start with '890'
  else if (!procedureInfo && procedureCode.toString().slice(0, 3) === '890') {
    return true;
  }
  return false;
}

export function isSurgical(procedureCode: number, cups: CupsJsonI) {
  const procedureInfo = cups[procedureCode];
  // the code exists on cups list and Qx param is 'S' which means it's surgical
  if (procedureInfo && procedureInfo['cupsQuirurgico'] === 1) {
    return true;
  }
  // the code doesn't exists on cups list but appears to be surgical
  // surgical procedures tend to start with '2'
  else if (!procedureInfo && procedureCode.toString().startsWith('2')) {
    return true;
  }
  return false;
}

export function dateFormatter(year: number, month: number, day: number) {
  var dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  } as Intl.DateTimeFormatOptions;
  return new Date(year, month, day).toLocaleDateString('es', dateOptions);
}
