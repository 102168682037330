import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { auth } from 'services/firebase';

export function useAuthStateChanged() {
  const [userId, setUserId] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    return auth.onAuthStateChanged((user: firebase.User) => {
      if (user) {
        user
          .getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (
              !!idTokenResult.claims?.admin ||
              !!idTokenResult.claims?.editor
            ) {
              setUserId(user.uid);
            } else {
              setUserId(null);
            }
          })
          .catch((error) => {
            console.log(error);
            setUserId(null);
          });
      } else {
        setUserId(null);
      }
    });
  }, []);

  return userId;
}
