import styled from 'styled-components';
import KanbanColumn, { TKanbanCard } from './KanbanColumn';
import { BOARD_COLUMNS, SHEET_STATES } from '../constants';

function KanbanBoard({ cardsList = [] }: { cardsList?: TKanbanCard[] }) {
  return (
    <StyledKanbanWrapper>
      {Object.values(SHEET_STATES).map((stateItem) => (
        <KanbanColumn
          key={`kanban-section-${stateItem}`}
          title={BOARD_COLUMNS[stateItem]}
          cards={cardsList.filter((cardItem) => cardItem.state === stateItem)}
        />
      ))}
    </StyledKanbanWrapper>
  );
}

const StyledKanbanWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0px 40px;
`;

export default KanbanBoard;
