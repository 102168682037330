import { getInvoiceGen, dateFormatter } from './utils';
import { cleanRecords } from './cleanRecords';
import { getAllPlainFiles } from './getPlainFiles';
import { CleanedRecordI, SheetRecordI, SheetDetailsI, CupsJsonI } from 'types';

export function separateNationalAndForeign(sheet: CleanedRecordI[]) {
  var FOREIGN_ID_TYPES = ['CE', 'PA', 'CD', 'PE', 'SV'];
  function startsWithNumber(string: String) {
    return string.match(/^\d/);
  }

  return sheet.reduce(
    (
      acc: { nationalSheet: CleanedRecordI[]; foreignSheet: CleanedRecordI[] },
      item,
    ) => {
      if (
        FOREIGN_ID_TYPES.includes(item.id_type) ||
        !startsWithNumber(item.id_number)
      ) {
        acc.foreignSheet.push(item);
      } else {
        acc.nationalSheet.push(item);
      }
      return acc;
    },
    { nationalSheet: [], foreignSheet: [] },
  );
}

// This is the one, the only, the rips maker function 🏃‍♀️

export function getRipsArrays(
  sheet: SheetRecordI[],
  sheetInfo: SheetDetailsI,
  cups: CupsJsonI,
) {
  var invoiceGen = getInvoiceGen(1, sheetInfo.year, sheetInfo.month + 1);
  var cleanedSheet = cleanRecords(sheet, sheetInfo, invoiceGen, dateFormatter);

  // TODO: national and foreign should be only separated for Bogotá. Not Cundinamarca
  var { nationalSheet, foreignSheet } =
    separateNationalAndForeign(cleanedSheet);

  return {
    nationalRips: getAllPlainFiles(nationalSheet, sheetInfo, cups),
    foreignRips: getAllPlainFiles(foreignSheet, sheetInfo, cups),
  };
}
