import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Icon, Logo, ProfileButton } from '@datarips/components';
import { getCapitalizedString } from 'shared/utils/strings';
import routes from 'shared/constants/routes';
import { logout } from 'services/actions/auth';
import { auth } from 'services/firebase';

const MENU = [
  { route: routes.BOARD, text: 'RIPS', icon: 'kanban' },
  { route: routes.CLIENTS, text: 'Clientes', icon: 'users-three' },
  { route: routes.SHEETS, text: 'Planillas', icon: 'grid-nine' },
  // { route: routes.MONEY, text: 'Cobros', icon: 'receipt' },
  // { route: routes.SETTINGS, text: 'Ajustes', icon: 'gear-six' },
];

function Sidebar() {
  const currentLocation = useLocation();
  const currUser = { ...auth.currUser, displayName: 'Admin' };

  return (
    <>
      <ProfileButton
        nickname={currUser.displayName || getCapitalizedString(currUser.name)}
        position="left"
        onLogout={logout}
      />
      <Menu>
        {MENU.map(({ route, text, icon }, index) => (
          <Link
            key={`sidebar-tab-${index}`}
            to={route}
            data-selected={currentLocation.pathname === route}
          >
            <Icon icon={icon} weight="fill" size={28} />
            {text}
          </Link>
        ))}
      </Menu>
      <StyledLogo>
        <Link to={routes.HOME}>
          <Logo size="medium" />
        </Link>
      </StyledLogo>
    </>
  );
}

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 15px;

  & > * {
    color: ${(props) => props.theme.palette.neutral[700]};
    text-decoration: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
    font-size: 18px;

    display: flex;
    align-items: center;
    padding: 12px 24px;
    gap: 15px;
    i {
      padding: 3px;
    }
    &:hover {
      background-color: ${(props) => props.theme.palette.neutral[400]};
    }
    &[data-selected='true'] {
      color: ${(props) => props.theme.primary.main};
      background-color: ${(props) => props.theme.palette.neutral[400]};
    }
  }
`;

const StyledLogo = styled.span`
  opacity: 0.5;
  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
`;

export default Sidebar;
