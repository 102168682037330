import { useState } from 'react';
import * as authActions from 'services/actions/auth';

// components
import { Input, Spinner, Button } from '@datarips/components';
import StyledAuthForm from './StyledAuthForm';
import { errorMessages, IAuthCards, TAuthCardName } from '../constants';

interface IProps {
  initCard: TAuthCardName;
  cards: IAuthCards;
}

function getErrorMessage(errorCode: string) {
  return errorMessages[errorCode]
    ? errorMessages[errorCode]
    : errorMessages['default'];
}

const DEFAULT_INPUTS_STATE = { email: '', password: '' };

function AuthForm(props: IProps) {
  const [inputs, setInputs] = useState({ ...DEFAULT_INPUTS_STATE });
  const [loading, setLoading] = useState(false);
  const [activeCardId, setActiveCardId] = useState(props.initCard);
  const [error, setError] = useState<null | string>(null);
  const activeCard = props.cards[activeCardId];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    const cardAction = authActions[activeCardId];
    if (cardAction) {
      const response = await cardAction(inputs);
      if (response.error) {
        setError(response.error);
      }
      setLoading(false);
    }
  };

  return loading ? (
    <div>
      <Spinner type={2} />
    </div>
  ) : (
    <StyledAuthForm>
      <h1>{activeCard.title}</h1>
      <p>{activeCard.subtitle}</p>

      {error && <div className="error">{getErrorMessage(error)}</div>}

      {activeCard && activeCard.fields && (
        <form id="auth-form" onSubmit={handleSubmit}>
          {Object.keys(activeCard.fields).map((item) => (
            <Input
              {...activeCard.fields?.[item]}
              key={`auth-form-field-${item}`}
              name={item}
              onChange={handleInputChange}
            />
          ))}
        </form>
      )}

      {activeCard &&
        activeCard.links?.map((item) => (
          <Button
            key={'link-' + item.cardLink}
            isLink
            className="link"
            onClick={() => setActiveCardId(item.cardLink)}
          >
            {item.text}
          </Button>
        ))}

      {activeCard && activeCard.fields && (
        <Button type="submit" form="auth-form">
          {activeCard.title}
        </Button>
      )}
    </StyledAuthForm>
  );
}

export default AuthForm;
