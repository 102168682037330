import MONTHS from 'shared/constants/months';

export const getDateText = (date: Date) => `
  ${date.getDate()} ${MONTHS[date.getMonth()]} de ${date.getFullYear()}
`;
export const getShortDateText = (date: Date) => `
  ${date.getDate()} de ${getMonthText(date.getMonth())}
`;

export const getDateNumbers = (date: Date) => `
  ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}
`;

export const getDateTime = (date: Date) => `
  ${String(date.getHours()).padStart(2, '0')}:${String(
  date.getMinutes(),
).padStart(2, '0')}
`;

export const getDateTextAndTime = (date: Date) =>
  date
    ? `
  ${getShortDateText(date)} a las ${getDateTime(date)}
`
    : '---';

export const getMonthText = (month: number) => MONTHS[month];

export const getShortMonthText = (month: number) =>
  MONTHS[month].slice(0, 3) + '.';

const getStringWithFirstUppercase = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

export const getCapitalizedString = (string: string) =>
  string
    ? string
        .split(' ')
        .map((word) => getStringWithFirstUppercase(word))
        .join(' ')
    : '';
