import { authErrors, firebaseAuthToCustomError } from 'modules/login/constants';
import { auth } from 'services/firebase';

function mapFirebaseToCustomError(firebaseErrorCode: string) {
  return firebaseAuthToCustomError[firebaseErrorCode]
    ? firebaseAuthToCustomError[firebaseErrorCode]
    : authErrors.DEFAULT;
}

type TAccount = {
  email: string;
  password: string;
};

// ---- SESSION ---- //

export async function login({ email, password }: TAccount) {
  try {
    const loginResponse = await auth.signInWithEmailAndPassword(
      email,
      password,
    );
    const idTokenResult = await loginResponse.user.getIdTokenResult();
    // Confirm the user is an Admin.
    if (!!idTokenResult.claims?.admin) {
      return { user: loginResponse.user, error: null };
    } else {
      return { user: null, error: authErrors.USER_NOT_ADMIN };
    }
  } catch (error: any) {
    return { user: null, error: mapFirebaseToCustomError(error.code) };
  }
}

export async function logout() {
  try {
    return await auth.signOut();
  } catch (error) {
    console.error(error);
    return null;
  }
}

// ---- CREATE ---- //

export async function signUp({ email, password }: TAccount) {
  try {
    return await auth.createUserWithEmailAndPassword(email, password);
  } catch (error) {
    console.error(error);
    return null;
  }
}
