import styled from 'styled-components';

export default styled.div`
  h1 {
    text-align: center;
    color: ${(props) => props.theme.textColor.main};
    margin-top: 0;
  }
  p {
    color: ${(props) => props.theme.palette.neutral[700]};
    margin-bottom: 1.8em;
  }
  .error {
    color: ${(props) => props.theme.palette.red[300]};
    margin-bottom: 1em;
    font-size: 0.9em;
  }

  form {
    margin-bottom: 1.8em;
    & > label {
      width: 100%;
      input {
        width: 100%;
      }
      &:not(:first-child) {
        margin-top: 1.1em;
      }
    }
  }

  .link {
    margin-bottom: 0.8em;
  }
`;
