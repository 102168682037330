import {
  CleanedRecordI,
  DateFormatterT,
  SheetDetailsI,
  SheetRecordI,
  SpecializationT,
} from 'types';
import { ZoneT } from 'shared/constants/sheets';

const STATE_IDS = {
  bogota: '11',
  cundinamarca: '25',
};

const DEFAULTS = {
  value: 0,
  municipality: '001',
  zone: ZoneT.Urban,
  diagnosis: 'A123',
};

const DEFAULTS_BY_SPECIALIZATION: {
  [key: SpecializationT]: Partial<SheetRecordI>;
} = {
  odontology: {
    diagnosis: 'K021',
  },
};

function getDefaultBySpecialization(specialization?: SpecializationT) {
  return specialization && DEFAULTS_BY_SPECIALIZATION[specialization]
    ? DEFAULTS_BY_SPECIALIZATION[specialization]
    : DEFAULTS;
}

function cleanInvoice(record: SheetRecordI, gen: Generator): SheetRecordI {
  return {
    ...record,
    invoice:
      record.invoice && record.invoice !== '0'
        ? record.invoice
        : gen.next().value,
  };
}

function cleanForeign(record: SheetRecordI): SheetRecordI {
  if (record.foreign_country) {
    let newRecord = { ...record };
    delete newRecord.foreign_country;
    return {
      ...newRecord,
      id_number: record.foreign_country.toString() + record.id_number,
    };
  } else {
    return record;
  }
}

function cleanDate(
  record: SheetRecordI,
  sheetInfo: SheetDetailsI,
  dateFormatter: DateFormatterT,
): CleanedRecordI {
  var newRecord: any = { ...record };
  delete newRecord.day;
  return {
    ...newRecord,
    date: dateFormatter(sheetInfo.year, sheetInfo.month, record.day),
  };
}

function cleanNames(record: SheetRecordI): SheetRecordI {
  return {
    ...record,
    first_lastname: record.first_lastname.trim(),
    second_lastname: record.second_lastname?.trim(),
    first_name: record.first_name.trim(),
    second_name: record.second_name?.trim(),
  };
}

function cleanMunicipality(record: SheetRecordI): SheetRecordI {
  var newRecord = { ...record };
  const municipality = record.municipality.trim();

  if (record.municipality.toString().length > 3) {
    newRecord.municipality = municipality.slice(-3);
    newRecord.state = municipality
      .slice(0, municipality.length - 3)
      .padStart(2, '0');
    if (Number(newRecord.state) === 11 && Number(newRecord.municipality) > 20) {
      newRecord.municipality = DEFAULTS.municipality; // Bogota can't have municipalities greater than 20
    }
  } else {
    newRecord.municipality = municipality.padStart(3, '0');
    if (Number(newRecord.municipality) <= 20) {
      newRecord.state = STATE_IDS.bogota;
    } else {
      newRecord.state = STATE_IDS.cundinamarca;
    }
  }

  return newRecord;
}

function cleanValue(record: SheetRecordI): SheetRecordI {
  return {
    ...record,
    value: record.value ? record.value : DEFAULTS.value,
  };
}

function cleanZone(record: SheetRecordI): SheetRecordI {
  return {
    ...record,
    zone: record.zone ? record.zone : DEFAULTS.zone,
  };
}

function cleanDiagnosis(
  record: SheetRecordI,
  sheetInfo: SheetDetailsI,
): SheetRecordI {
  const defaults = getDefaultBySpecialization(sheetInfo.author.specialization);
  return {
    ...record,
    diagnosis: record.diagnosis || defaults.diagnosis,
  };
}

export function cleanRecords(
  sheet: SheetRecordI[],
  sheetInfo: SheetDetailsI,
  invoiceGen: Generator,
  dateFormatter: DateFormatterT,
): CleanedRecordI[] {
  return sheet.map((record) =>
    cleanDate(
      cleanNames(
        cleanForeign(
          cleanMunicipality(
            cleanValue(
              cleanZone(
                cleanDiagnosis(cleanInvoice(record, invoiceGen), sheetInfo),
              ),
            ),
          ),
        ),
      ),
      sheetInfo,
      dateFormatter,
    ),
  );
}
