import {
  CleanedRecordI,
  SheetDetailsI,
  DateFormatterT,
  CupsJsonI,
} from 'types';
import { isSurgical, isConsult, dateFormatter } from './utils';

function getUsers(sheet: CleanedRecordI[], sheetInfo: SheetDetailsI) {
  var recordIds = sheet.map(function getId(record) {
    return record.id_number;
  });
  return Array.from(new Set(recordIds)).map((id_number) => {
    var record = sheet.find((record) => record.id_number === id_number);
    if (record) {
      return [
        record.id_type,
        record.id_number,
        sheetInfo.meta.admin.code,
        sheetInfo.meta.user_type,
        record.first_lastname,
        record.second_lastname,
        record.first_name,
        record.second_name,
        record.age,
        sheetInfo.meta.age_unit,
        record.sex,
        record.state,
        // TODO: use state code from sheetInfo when state is not defined
        // record.state ? record.state : sheetInfo.meta.department_code,
        record.municipality,
        record.zone,
      ];
    } else {
      return [];
    }
  });
}

function getTrans(
  sheet: CleanedRecordI[],
  sheetInfo: SheetDetailsI,
  dateFormatter: DateFormatterT,
) {
  var firstMonthDay = dateFormatter(sheetInfo.year, sheetInfo.month, 1);
  var lastMonthDay = dateFormatter(sheetInfo.year, sheetInfo.month + 1, 0);

  return sheet.map((record) => [
    sheetInfo.author.code,
    sheetInfo.author.name,
    sheetInfo.author.id_type,
    sheetInfo.author.id_number,
    record.invoice,
    record.date,
    firstMonthDay,
    lastMonthDay,
    sheetInfo.meta.admin.code,
    sheetInfo.meta.admin.name,
    '',
    '',
    '',
    0,
    0,
    0,
    record.value,
  ]);
}

function getConsu(sheet: CleanedRecordI[], sheetInfo: SheetDetailsI) {
  return sheet.map((record) => [
    record.invoice,
    sheetInfo.author.code,
    record.id_type,
    record.id_number,
    record.date,
    '',
    record.procedure,
    sheetInfo.meta.consult_purpose,
    sheetInfo.meta.external_cause,
    record.diagnosis,
    '',
    '',
    '',
    sheetInfo.meta.diagnosis_type,
    record.value,
    0,
    record.value,
  ]);
}

function getProce(
  sheet: CleanedRecordI[],
  sheetInfo: SheetDetailsI,
  cups: CupsJsonI,
) {
  return sheet.map((record) => {
    const isSurgicalRecord = isSurgical(record.procedure, cups);
    return [
      record.invoice,
      sheetInfo.author.code,
      record.id_type,
      record.id_number,
      record.date,
      record.authorization,
      record.procedure,
      sheetInfo.meta.procedure_scope,
      sheetInfo.meta.procedure_purpose,
      '',
      isSurgicalRecord ? record.diagnosis : '',
      '',
      '',
      isSurgicalRecord ? sheetInfo.meta.surgical_type : '',
      record.value,
    ];
  });
}

function getConsuAndProceSheets(sheet: CleanedRecordI[], cups: CupsJsonI) {
  return sheet.reduce(
    (
      acc: {
        consultsSheet: CleanedRecordI[];
        proceduresSheet: CleanedRecordI[];
      },
      record,
    ) => {
      if (isConsult(record.procedure, cups)) {
        acc.consultsSheet.push(record);
      } else {
        acc.proceduresSheet.push(record);
      }
      return acc;
    },
    { consultsSheet: [], proceduresSheet: [] },
  );
}

export function getAllPlainFiles(
  sheet: CleanedRecordI[],
  sheetInfo: SheetDetailsI, // TODO: change it for sheetMetadata to be more explicit
  cups: CupsJsonI,
) {
  var { consultsSheet, proceduresSheet } = getConsuAndProceSheets(sheet, cups);

  return {
    US: getUsers(sheet, sheetInfo),
    AF: getTrans(sheet, sheetInfo, dateFormatter),
    AC: getConsu(consultsSheet, sheetInfo),
    AP: getProce(proceduresSheet, sheetInfo, cups),
  };
}
