import XLSX from 'xlsx';
import MONTHS from 'shared/constants/months';

// Hidden anchor to download files
var hiddenElement = document.createElement('a');
hiddenElement.target = '_blank';

// DOWNLOAD JSON PROCEDURE

export function downloadJson(sheet, authorName) {
  hiddenElement.href =
    'data:text/json;charset=utf-8,%EF%BB%BF' +
    encodeURI(JSON.stringify(sheet.data));
  hiddenElement.download =
    getFileName(authorName, sheet.month, sheet.year) + '.json';
  hiddenElement.click();
}

// DOWNLOAD CSV PROCEDURE

export function downloadCsv(csvString, authorName, month, year) {
  hiddenElement.href =
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvString);
  hiddenElement.download = getFileName(authorName, month, year) + '.csv';
  hiddenElement.click();
}

export function downloadTxt(txtString, fileName) {
  const blob = new Blob(
    [
      // TODO: check blob not working on Cundinamarca validation
      // also check special characters not accepted when no blob
      // new Uint8Array([0xef, 0xbb, 0xbf]),
      txtString,
    ],
    {
      type: 'text/csv;charset=utf-8',
      endings: 'native',
    },
  );
  hiddenElement.href = URL.createObjectURL(blob);
  hiddenElement.download = fileName + '.txt';
  hiddenElement.click();
}

// CSV STRING FUNCTIONS

// Row - Single array
function getRowArray(item, index, csvHeaders, month, year) {
  return csvHeaders.map((header) => {
    const itemValue = item[header.key];

    switch (header.key) {
      case 'day':
        return getFullDateString(itemValue, month, year);
      case 'value':
        return itemValue || '0';
      case 'invoice':
        return itemValue || getInvoiceString(month, year, index);
      case 'municipality':
        return String(itemValue).padStart(3, '0');
      default:
        return itemValue || '';
    }
  });
}

// Datasheet - Array of arrays
export function getSheetArray(datasheet, csvHeaders) {
  let valuesArray = datasheet.data.map((item, index) => {
    return getRowArray(
      item,
      index,
      csvHeaders,
      datasheet.month,
      datasheet.year,
    );
  });

  return [csvHeaders.map((header) => header.text), ...valuesArray];
}

// .csv - String
export function getCsvString(data) {
  return data.map((row) => row.join(',')).join('\n');
}

// .xlsx - Object
export function downloadXlsx(data, fileName) {
  let workbook = XLSX.utils.book_new();
  Object.keys(data).forEach((item) => {
    let worksheet = XLSX.utils.aoa_to_sheet(data[item]);
    XLSX.utils.book_append_sheet(workbook, worksheet, item);
  });
  XLSX.writeFile(workbook, fileName + '.xlsx', {
    bookType: 'xlsx',
    type: 'array',
  });
}

// CUSTOM STRINGS DATA

function getFullDateString(day, month, year) {
  return [
    String(day).padStart(2, '0'),
    String(month + 1).padStart(2, '0'),
    year,
  ].join('/');
}

function getInvoiceString(month, year, index) {
  return (
    year + String(month + 1).padStart(2, '0') + String(index).padStart(3, '0')
  );
}

function getFileName(authorName, month, year) {
  return [authorName, MONTHS[month], year].join('-');
}
