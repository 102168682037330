import styled from 'styled-components';
import { Card } from '@datarips/components';
import { getCapitalizedString } from 'shared/utils/strings';
import { getSpecializationText } from '../helpers';
import { SpecializationT } from 'types';

export type TClientCard = {
  id: string;
  displayName: string;
  name: string;
  photoURL: string;
  code: number;
  id_type: number;
  id_number: number;
  isActive: boolean;
  email: string;
  specialization: SpecializationT;
  isDummy?: boolean;
};

const DEFAULT_CLIENT_IMG_PATH = '/img/profile/default.png';

function ClientCard(props: TClientCard) {
  return (
    <StyledClient>
      <div className="heading">
        <img src={props.photoURL || DEFAULT_CLIENT_IMG_PATH} alt="client" />
        <div>
          <h5>
            {getCapitalizedString(props.displayName || props.name)}{' '}
            {props.isDummy && '*'}
          </h5>
          <small>{getSpecializationText(props.specialization)}</small>
        </div>
      </div>
      <div className="data">
        <span>{props.code}</span>
        <span
          className={
            props.id_type && props.id_number && props.id_number > 100000
              ? ''
              : 'error'
          }
        >
          {props.id_type} {props.id_number}
        </span>
        <span>{props.email}</span>
        {/* TODO: Remove isActive for now as we're only showing active users*/}
        {/* <span>{props.isActive ? 'Activo ✓' : 'No Activo'}</span> */}
      </div>
    </StyledClient>
  );
}

const StyledClient = styled(Card)`
  overflow: hidden;
  .heading {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    h5 {
      color: ${(props) => props.theme.textColor.main};
      margin: 0;
    }
    img {
      height: 40px;
      border-radius: 50%;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
    }
    h5,
    small {
      white-space: nowrap;
    }
  }
  .data > * {
    display: block;
    &.error {
      background-color: ${(props) => props.theme.palette.red[300]};
    }
  }

  &:hover .heading > img {
    -webkit-filter: none;
    filter: none;
  }
`;

export default ClientCard;
