import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CardsSection from 'shared/components/organisms/CardsSection';
import Header, { Divider } from 'shared/components/organisms/Header';
import { fetchActiveClientsGroupedByGroup } from 'services/actions/clients';
import ClientCard, { TClientCard } from './components/ClientCard';
import { Button, Dialog } from '@datarips/components';
import ClientForm from './components/ClientForm';

const GROUPS = [
  'Grupo 1 (días 1-10)',
  'Grupo 2 (días 11-20)',
  'Grupo 3 (días 21-30)',
];

type TGroupedClients =
  | [TClientCard[], TClientCard[], TClientCard[]]
  | undefined;

function Clients() {
  const [groupedClients, setGroupedClients] = useState<TGroupedClients>();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const clientsCount = groupedClients?.reduce(
    (acc, item) => acc + item.length,
    0,
  );

  useEffect(() => {
    fetchActiveClientsGroupedByGroup().then((response) =>
      setGroupedClients(response),
    );
  }, []);

  const openDialog = () => setIsDialogOpened(true);
  const closeDialog = () => setIsDialogOpened(false);

  return (
    <>
      <Header>
        <div className="title">
          <h1>Clientes</h1>
          <Divider />
          <h4>
            {clientsCount ? (
              <>
                {clientsCount} cliente{clientsCount !== 1 && 's'} activo
                {clientsCount !== 1 && 's'}
              </>
            ) : (
              '...'
            )}
          </h4>
        </div>
        <Button icon="plus" onClick={openDialog}>
          Crear cliente
        </Button>
      </Header>

      <Content>
        {groupedClients === undefined
          ? 'Cargando clientes ...'
          : GROUPS.map((text, index) => (
              <CardsSection
                key={`clients-group-${index}`}
                title={text}
                cardsDirection="row"
                cards={groupedClients[index].map((item) => (
                  <ClientCard key={`client-card-${item.code}`} {...item} />
                ))}
              />
            ))}
      </Content>

      <Dialog
        isOpened={isDialogOpened}
        onClose={closeDialog}
        onBackdropClick={closeDialog}
        isCentered={false}
        closeIcon={false}
        title="👤 Crear un nuevo cliente"
        cancelButton="Cancelar"
        okButton="Crear"
        onCancel={closeDialog}
        onOk={() => formRef.current?.requestSubmit()}
      >
        <ClientForm formRef={formRef} />
      </Dialog>
    </>
  );
}

const Content = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: calc(100% - 128px);
  overflow-y: auto;
  padding-bottom: 60px;
`;

export default Clients;
