import styled, { css } from 'styled-components';

function getMessageState(remainingDays: number) {
  if (remainingDays < 0) {
    return {};
  } else if (remainingDays <= 2) {
    return { icon: '😨', color: 'errorColor', iconAriaLabel: 'scared' };
  } else if (remainingDays <= 4) {
    return { icon: '🧐', color: 'warningColor', iconAriaLabel: 'thinking' };
  } else {
    return { icon: '😎', color: 'infoColor', iconAriaLabel: 'cool' };
  }
}

function RemainingDaysMessage(props: {
  currentDate: number;
  deadlineDate: number;
}) {
  const remainingDays = props.deadlineDate - props.currentDate;
  const { icon, color, iconAriaLabel } = getMessageState(remainingDays);

  return (
    <StyledRemainingDays color={color}>
      <span role="img" aria-label={iconAriaLabel}>
        {icon}
      </span>
      {remainingDays < 0 ? (
        <strong>Hoy no hay entrega ... relax</strong>
      ) : remainingDays === 0 ? (
        <strong>Se entrega HOY</strong>
      ) : (
        <>
          <strong>{remainingDays}</strong> día{remainingDays > 1 && 's'} para la
          entrega
        </>
      )}
    </StyledRemainingDays>
  );
}

const StyledRemainingDays = styled.span<{ color?: string }>`
  span[role='img'] {
    margin-right: 5px;
    font-size: ${(props) => props.theme.typeScale.desktop.xs};
  }

  /* conditional styles */
  ${(props) =>
    props.color &&
    css`
      color: ${props.theme.status[props.color]?.main};
    `}
`;

export default RemainingDaysMessage;
