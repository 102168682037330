import { SpecializationT } from 'types';

export const SPECIALIZATIONS: { text: string; value: SpecializationT }[] = [
  { text: 'Anestesiología', value: 'anesthesiology' },
  { text: 'Dermatología', value: 'dermatology' },
  { text: 'Fisioterapia', value: 'physiotherapy' },
  { text: 'Imagenología', value: 'imaging' },
  { text: 'Laboratorio', value: 'laboratory' },
  { text: 'Odontología', value: 'odontology' },
  { text: 'Optometría', value: 'optometry' },
  { text: 'Ortopedia', value: 'orthopedics' },
  { text: 'Psicología', value: 'psychology' },
  { text: 'Restauración capilar', value: 'hairRestoration' },
];
export const DEFAULT_SPECIALIZATION = 'Prestador de la salud';

export const ID_TYPES = [
  { text: 'Cédula', value: 'CC' },
  { text: 'NIT', value: 'NI' },
  { text: 'Registro civil', value: 'RC' },
  { text: 'Cédula de extranjería', value: 'CE' },
];

export const LOCATIONS = [
  { text: 'Bogotá', value: 'bogota' },
  { text: 'Cundinamarca', value: 'cundinamarca' },
];
export const DEFAULT_LOCATION = 'bogota';

export const idTypesArray = ID_TYPES.map((item) => item.text);
export const locationsArray = LOCATIONS.map((item) => item.value);
