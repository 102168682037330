import firebase from 'firebase/app';
import 'firebase/firestore';
import { TClientCard } from 'modules/clients/components/ClientCard';
import { clientsRef } from 'services/firebase';
import { DEFAULT_LOCATION } from 'shared/constants/clients';
import { getPrevMonthDate } from 'shared/utils/misc';
import { ClientI, ClientDataI } from 'types';

// ---- HELPERS ---- //

function getFormattedClient(doc: any) {
  let client = doc.data();
  // fill in some attributes for legacy clients
  client.activationDate = client.activationDate?.toDate() || getPrevMonthDate(); // the clients usually need previous month to be active
  client.location = client.location || DEFAULT_LOCATION;
  return {
    ...client,
    id: doc.id,
  } as ClientI;
}

// ---- CREATE ---- //

export async function createClient(uid: string, clientData: ClientDataI) {
  try {
    return await clientsRef.doc(uid).set(clientData);
  } catch (error) {
    console.error(error);
    return null;
  }
}

// ---- READ ---- //

export async function fetchClients() {
  try {
    const response = await clientsRef.get();
    const clients = response.docs.map(getFormattedClient);
    return clients;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchActiveClientsGroupedByGroup() {
  try {
    const response = await clientsRef.get();
    const clients = response.docs.map(getFormattedClient);
    return clients
      .filter((item: any) => item.code !== undefined && item.isActive)
      .reduce(
        (
          acc: [TClientCard[], TClientCard[], TClientCard[]],
          item: TClientCard,
        ) => {
          const lastDigits = Number(String(item.code).slice(-4, -2));
          if (0 <= lastDigits && lastDigits <= 33) {
            acc[0].push(item); // group 1
          } else if (33 < lastDigits && lastDigits <= 66) {
            acc[1].push(item); // group 2
          } else {
            acc[2].push(item); // group 3
          }
          return acc;
        },
        [[], [], []],
      );
  } catch (error) {
    console.error(error);
    return null;
  }
}

// ---- WRITE ---- //

export async function setClientSheetValidationState(
  uid: string,
  sheetId: string,
  isValidated: boolean,
) {
  try {
    const ref = clientsRef.doc(uid).collection('sheets').doc(sheetId);
    const data = await ref.get((doc: any) => doc.data());

    const shouldDateBeUpdated =
      isValidated || (!isValidated && data.validationDate);
    const validationDate = isValidated
      ? new Date()
      : firebase.firestore.FieldValue.delete();

    return ref.update({
      isValidated,
      ...(isValidated && { validationDate: new Date() }),
      ...(!shouldDateBeUpdated && { validationDate }),
    });
  } catch (error) {
    console.error(error);
  }
}
