import { SelectHTMLAttributes } from 'react';
import styled from 'styled-components';

const DEFAULT_PLACEHOLDER = 'Elige una opción';

type TSelectOption = { text: string; value: string };

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options?: TSelectOption[];
  label?: string;
  placeholder?: string;
}

export default function Select({
  options,
  label,
  placeholder,
  id,
  ...props
}: ISelectProps) {
  return (
    <StyledSelect>
      {label && <label htmlFor={id}>{label}</label>}
      <select id={id} {...props}>
        {
          <option value={undefined}>
            {placeholder || DEFAULT_PLACEHOLDER}
          </option>
        }
        {options?.map(({ text, value }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>
    </StyledSelect>
  );
}

const StyledSelect = styled.label`
  display: inline-block;
  position: relative;
  label {
    font-weight: 500;
    color: #11524e;
    margin-bottom: 0.26em;
    display: inline-block;
  }
  select {
    width: calc(100% - 1.6em);
    font-size: 1em;
    background-color: #ffffff;
    font-family: NunitoSans, Roboto, sans-serif;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    padding: 0.6em 0.8em;
    outline: none;
  }
`;
