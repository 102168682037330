import styled, { css } from 'styled-components';

type TSection = {
  title: string;
  cards: React.ReactNode[];
  cardsDirection?: 'column' | 'row';
};

function CardsSection({ title = '', cards = [], ...props }: TSection) {
  return (
    <StyledSection {...props}>
      <div className="title">
        <span>{title.toUpperCase()}</span>
        <span>{cards.length}</span>
      </div>
      <div className="cards">{cards}</div>
    </StyledSection>
  );
}

const StyledSection = styled.div<{ cardsDirection?: 'column' | 'row' }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    background-color: #f4f4f4;
    &.title {
      position: sticky;
      top: 0;
      z-index: 2;
      color: ${(props) => props.theme.palette.neutral[800]};
      font-family: ${(props) => props.theme.fonts.titles};
      border-radius: 5px 5px 0 0;
      padding: 14px 7px;
      span:first-child {
        font-weight: 800;
        margin-right: 7px;
        margin: 0 7px 0;
      }
    }
    &.cards {
      height: 100%;
      border-radius: 0 0 5px 5px;
      padding: 0 7px 7px;
      z-index: 1;
    }
  }

  /* conditional styles */
  ${(props) =>
    props.cardsDirection === 'column'
      ? css`
          .cards {
            display: flex;
            flex-direction: column;
            gap: 7px;
          }
        `
      : css`
          .cards {
            display: grid;
            gap: 7px;
          }
          @media (min-width: 700px) {
            .cards {
              grid-template-columns: repeat(2, 1fr);
            }
          }
          @media (min-width: 1000px) {
            .cards {
              grid-template-columns: repeat(3, 1fr);
            }
          }
          @media (min-width: 1300px) {
            .cards {
              grid-template-columns: repeat(4, 1fr);
            }
          }
        `}
`;

export default CardsSection;
