const routes = {
  HOME: '/',
  LOGIN: '/login',
  BOARD: '/tablero',
  SHEETS: '/planillas',
  CLIENTS: '/clientes',
  MONEY: '/cobros',
  SETTINGS: '/configuración',
};

export default routes;
