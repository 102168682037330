import ReactDOM from 'react-dom';
import { DataripsStyleWrapper, defaultTheme } from '@datarips/components';
import App from './app/App';

ReactDOM.render(
  <DataripsStyleWrapper theme={defaultTheme}>
    <App />
  </DataripsStyleWrapper>,
  document.getElementById('root'),
);
