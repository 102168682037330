export enum SHEET_STATES {
  NotCreated = 'NOT_CREATED',
  Editing = 'EDITING',
  Sent = 'SENT',
  Validated = 'VALIDATED',
}

export const BOARD_COLUMNS: { [name in SHEET_STATES]: string } = {
  [SHEET_STATES.NotCreated]: 'Por llenar',
  [SHEET_STATES.Editing]: 'En edición',
  [SHEET_STATES.Sent]: 'Recibida',
  [SHEET_STATES.Validated]: 'Validada',
};
