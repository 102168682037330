import styled from 'styled-components';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 40px;
  gap: 50px;

  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .metadata {
    font-size: 18px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .title > *,
  .metadata > * {
    margin: 0;
  }
`;

export default StyledHeader;

export const Divider = styled.div`
  &:after {
    content: '|';
    font-size: 28px;
    font-weight: 400;
    color: ${(props) => props.theme.palette.neutral[500]};
    padding: 0 15px;
  }
`;
