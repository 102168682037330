import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { clientsRef, dataSheetsRef, dataPropsRef } from 'services/firebase';
import {
  getSheetArray,
  downloadXlsx,
  downloadJson,
  downloadTxt,
  getCsvString,
} from 'shared/utils/excel';
import { getFileName } from 'shared/utils/strings';
import { getDateTextAndTime } from 'shared/utils/prettify';
import { getRipsArrays } from 'services/ripsMaker/getRips';
import { Card, Button } from '@datarips/components';
import cups from 'shared/constants/cleanedCups.json';
import MONTHS from 'shared/constants/months';
import { CSV_HEADERS } from 'shared/constants/sheets';
import { getSpecializationText } from 'modules/clients/helpers';
import { getFormattedSheet } from 'services/actions/sheets';

const DEFAULT_METADATA = {
  admin: { code: 'SDS001', name: 'SDS' }, // Bogotá info
  user_type: 4,
  age_unit: 1,
  department_code: 11,
  consult_purpose: 10,
  external_cause: 13,
  diagnosis_type: 2,
  procedure_scope: 1,
  procedure_purpose: 2,
  surgical_type: 1,
};

const SheetsConventions = () => (
  <span
    style={{
      display: 'inline-flex',
      gap: '30px',
      marginBottom: '20px',
      border: '1px solid #b4b4b4',
      padding: '5px 10px',
    }}
  >
    <span>🔸 En edición</span>
    <span>🔹 Recibida</span>
    <span>✅ Validada</span>
  </span>
);

function Sheets() {
  const [users, setUsers] = useState([]);
  const [dataProps, setDataProps] = useState([]);
  const [userSelect, setUserSelect] = useState(null);
  const [sheetSelect, setSheetSelect] = useState(null);
  const [selectData, setSelectData] = useState({});
  const selectedSheetMetadata =
    selectData.user && selectData.sheet
      ? selectData.user.sheets.find(({ id }) => id === selectData.sheet.id)
      : {};

  useEffect(() => {
    clientsRef.get().then((snap) => {
      let users = snap.docs
        .map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        })
        .filter(function isActive(user) {
          return user.isActive;
        })
        .sort(function byDisplayName(a, b) {
          return a.displayName < b.displayName ? -1 : 1;
        });
      setUsers(users);
    });

    dataPropsRef.get().then((snap) => {
      let dataProps = snap.docs.map((doc) => {
        return {
          id: doc.id,
          ...doc.data(),
        };
      });
      setDataProps(dataProps);
    });
  }, []);

  useEffect(() => {
    if (userSelect) {
      clientsRef
        .doc(userSelect)
        .collection('sheets')
        .orderBy('year', 'desc')
        .orderBy('month', 'desc')
        .get()
        .then((snap) => {
          const sheets = snap.docs.map(getFormattedSheet);
          setSelectData({
            ...selectData,
            user: {
              ...users.find((item) => item.id === userSelect),
              sheets,
            },
          });
        });
    }
  }, [userSelect]);

  useEffect(() => {
    if (sheetSelect) {
      dataSheetsRef
        .doc(sheetSelect)
        .get()
        .then((snap) => {
          setSelectData({
            ...selectData,
            sheet: getFormattedSheet(snap),
          });
        });
    }
  }, [sheetSelect]);

  const handleJsonDownload = () => {
    if (dataProps) {
      downloadJson(selectData.sheet, selectData.user.name);
    }
  };

  const handleRipsDownload = () => {
    const user = selectData.user;

    if (user.id_type && user.id_number && user.id_number > 100000) {
      const { month, year } = selectData.sheet;
      let sheetInfo = {
        author: user,
        meta: user.sheet_meta || DEFAULT_METADATA,
        month,
        year,
      };
      let { nationalRips, foreignRips } = getRipsArrays(
        selectData.sheet.data,
        sheetInfo,
        cups,
      );
      let database = getSheetArray(selectData.sheet, CSV_HEADERS);

      downloadXlsx(
        { database },
        'Planilla ' + getFileName(user.displayName, month, year),
      );

      Object.keys(nationalRips).forEach((item) => {
        if (nationalRips[item].length !== 0) {
          const fileName =
            item + year + (month + 1).toString().padStart(2, '0');
          downloadTxt(getCsvString(nationalRips[item]), fileName);
        }
        if (foreignRips[item].length !== 0) {
          const fileName =
            item + year + (month + 1).toString().padStart(2, '0');
          downloadTxt(getCsvString(foreignRips[item]), fileName + 'e');
        }
      });
    } else {
      alert(
        'El número de identificación del cliente no existe o no es válido.',
      );
    }
  };

  return (
    <div style={{ height: '100%' }}>
      <h1 style={{ margin: 0, padding: '40px' }}>Lista de usuarios:</h1>

      <div
        style={{
          padding: '0 40px',
          height: 'calc(100% - 128px)',
          overflowY: 'scroll',
          paddingBottom: '60px',
        }}
      >
        {users.length === 0 ? (
          'Cargando planillas ...'
        ) : (
          <CardsList
            list={users}
            selected={userSelect}
            onItemClick={(id) => {
              setUserSelect(id);
              setSheetSelect(null);
            }}
          />
        )}
        {userSelect && selectData.user && (
          <>
            <h3>Planillas de {selectData.user.displayName}:</h3>
            <SheetsConventions />
            <CardsList
              list={selectData.user.sheets.map((item) => ({
                ...item,
                name: `${item.isValidated ? '✅' : item.isSent ? '🔹' : '🔸'} ${
                  MONTHS[item.month]
                } ${item.year}`,
              }))}
              columns={6}
              selected={sheetSelect}
              onItemClick={setSheetSelect}
            />
          </>
        )}
        {sheetSelect && selectData.sheet && (
          <>
            <h3>Detalles de planilla:</h3>
            <SheetDetails
              sheet={{
                ...selectData.sheet,
                ...selectedSheetMetadata,
              }}
              onJsonDownload={handleJsonDownload}
              onRipsDownload={handleRipsDownload}
            />
          </>
        )}
      </div>
    </div>
  );
}

const StyledCardList = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  [data-selected='true'] {
    border: 2px solid ${(props) => props.theme.secondary.main};
  }
  h5 {
    margin: 0;
  }
  small {
    color: ${(props) => props.theme.palette.neutral[700]};
  }
`;

function CardsList({ list, selected, onItemClick }) {
  return (
    <StyledCardList>
      {list.map((item) => (
        <Card
          key={item.id}
          data-selected={selected === item.id}
          onClick={() => onItemClick(item.id)}
        >
          <h5>
            {item.displayName || item.name} {item.isDummy && '*'}
          </h5>
          {item.specialization && (
            <small>{getSpecializationText(item.specialization)}</small>
          )}
        </Card>
      ))}
    </StyledCardList>
  );
}

function SheetDetails({ sheet, onRipsDownload }) {
  return (
    <>
      <ul>
        <li>
          <b>Enviado: </b>
          <span>{sheet.isSent ? 'Si' : 'No'}</span>
        </li>
        <li>
          <b>Validado: </b>
          <span>{sheet.isValidated ? 'Si' : 'No'}</span>
        </li>
        {sheet.isValidated && (
          <li>
            <b>Validado en: </b>
            <span>{getDateTextAndTime(sheet.validationDate)}</span>
          </li>
        )}
        <li>
          <b># Registros: </b>
          <span>{sheet.data.length}</span>
        </li>
        <li>
          <b>Año: </b>
          <span>{sheet.year}</span>
        </li>
        <li>
          <b>Mes: </b>
          <span>{MONTHS[sheet.month]}</span>
        </li>
        <li>
          <b>Creado en: </b>
          <span>
            {getDateTextAndTime(
              sheet.createdAt ? sheet.createdAt.toDate() : undefined,
            )}
          </span>
        </li>
        <li>
          <b>Últ. vez editado en: </b>
          <span>
            {getDateTextAndTime(
              sheet.updatedAt.toDate() ? sheet.updatedAt.toDate() : undefined,
            )}
          </span>
        </li>
      </ul>

      <Button
        style={{ display: 'inline', marginRight: '1em' }}
        onClick={onRipsDownload}
        disabled={!sheet.isSent}
      >
        Descargar RIPS
      </Button>
      <a
        href={`https://planillas.datarips.com/editor/${sheet.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button
          style={{ display: 'inline' }}
          isOutlined
          disabled={!sheet.isSent}
        >
          Ver planilla
        </Button>
      </a>
    </>
  );
}

export default Sheets;
