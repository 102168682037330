import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '@datarips/components';
import Select from 'shared/components/molecules/Select';
import {
  SPECIALIZATIONS,
  ID_TYPES,
  DEFAULT_LOCATION,
} from 'shared/constants/clients';
import PasswordGeneratorInput from './PasswordGeneratorInput';
import { signUp } from 'services/actions/auth';
import { createClient } from 'services/actions/clients';
import { SpecializationT, IdTypeT, LocationT } from 'types';

type TFormState = {
  name: string;
  email: string;
  code: string;
  displayName?: string;
  specialization?: SpecializationT;
  id_type?: IdTypeT;
  id_number?: number;
  location: LocationT;
  isDummy?: boolean;
};
interface IClientFormProps {
  formRef?: React.RefObject<HTMLFormElement>;
}

const initialState: TFormState = {
  name: '',
  email: '',
  code: '',
  specialization: '',
  displayName: '',
  location: DEFAULT_LOCATION,
  isDummy: false,
};

function ClientForm({ formRef }: IClientFormProps) {
  const [state, setState] = useState(initialState);
  const [password, setPassword] = useState('');

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    setState({
      ...state,
      [e.target.name]:
        e.target.type === 'number' ? Number(e.target.value) : e.target.value,
    });
  }

  function handlePasswordChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    setPassword(e.target.value);
  }

  function handleIsDummyChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState({ ...state, isDummy: e.target.checked });
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { email } = state;
    const userCredentials = await signUp({ email, password });
    const userData = { ...state, isActive: true, activationDate: new Date() };
    createClient(userCredentials.user.uid, userData);
  }

  return (
    <StyledClientForm ref={formRef} onSubmit={handleSubmit}>
      <Input
        name="name"
        label="Nombre completo *"
        placeholder="Juan Carlos Hernández López"
        value={state.name}
        onChange={handleInputChange}
        required
      />
      <Input
        name="email"
        label="Correo electrónico *"
        placeholder="juan.lopez@email.com"
        type="email"
        value={state.email}
        onChange={handleInputChange}
        required
      />
      <Input
        name="code"
        label="Cód. de habilitación *"
        placeholder="000000000000"
        type="tel"
        minLength={12}
        maxLength={12}
        value={state.code}
        onChange={handleInputChange}
        required
      />
      <PasswordGeneratorInput name="password" onChange={handlePasswordChange} />
      <hr />
      <Select
        name="id_type"
        label="Tipo de Identificación"
        options={ID_TYPES}
        value={state.id_type}
        onChange={handleInputChange}
      />
      <Input
        name="id_number"
        label="Número de Identificación"
        placeholder="7123456"
        type="number"
        minLength={6}
        maxLength={10}
        value={state.id_number}
        onChange={handleInputChange}
      />
      <Input
        name="displayName"
        label="Nombre corto"
        placeholder="Juan Carlos"
        maxLength={15}
        value={state.displayName}
        onChange={handleInputChange}
      />
      <Select
        name="specialization"
        label="Especialización"
        options={SPECIALIZATIONS}
        value={state.specialization}
        onChange={handleInputChange}
      />
      <Input
        name="isDummy"
        label="¿Es dummy?"
        maxLength={15}
        type="checkbox"
        checked={state.isDummy}
        onChange={handleIsDummyChange}
      />
    </StyledClientForm>
  );
}

const StyledClientForm = styled.form`
  section {
    display: flex;
    margin-bottom: 20px;
  }
  h5,
  & > label {
    margin-bottom: 15px;
    min-width: 400px;
  }
  hr {
    border: none;
    height: 1px;
    background-color: ${(props) => props.theme.palette.neutral[600]};
  }
`;

export default ClientForm;
