import styled from 'styled-components';

// import components
import AuthForm from './components/AuthForm';
import SemiHalfTemplate from 'shared/components/templates/SemiHalfTemplate';
import { authCards } from './constants';

// images
const rightImgPath = '/img/graphics/login.png';

function Login() {
  return (
    <SemiHalfTemplate>
      <Left className="left">
        <AuthForm initCard="login" cards={authCards} />
      </Left>

      <Right className="right" />
    </SemiHalfTemplate>
  );
}

const Left = styled.div`
  & > * {
    width: 35%;
    button {
      width: 100%;
    }
  }
`;
const Right = styled.div`
  height: 100%;
  background-image: url(${rightImgPath});
  background-size: cover;
  background-position: center;
`;

export default Login;
