import { clientsRef } from 'services/firebase';
import { getDateStartingMonth, getPrevMonthDate } from 'shared/utils/misc';
import { ClientSheetI } from 'types';

type TDateRange = { startDate: Date; endDate?: Date };

// ---- QUERIES ---- //

function getOrderedClientSheetsQuery(clientId: string) {
  return clientsRef
    .doc(clientId)
    .collection('sheets')
    .orderBy('year', 'desc')
    .orderBy('month', 'desc');
}

// ---- HELPERS ---- //

export function getFormattedSheet(doc: any) {
  const sheet = doc.data();

  // set some attributes for legacy sheets
  sheet.isSent = sheet.isSent || sheet.sent;
  delete sheet.sent;
  if (sheet.validationDate) {
    sheet.validationDate = sheet.validationDate?.toDate();
  }

  return {
    ...sheet,
    id: doc.id,
  } as ClientSheetI;
}

function isSheetOnDateRange(
  sheet: ClientSheetI,
  { startDate, endDate }: TDateRange,
) {
  if (startDate && endDate) {
    // ignore times and days, use only year and month
    startDate = getDateStartingMonth(startDate);
    endDate = getDateStartingMonth(endDate);
    const sheetDate = new Date(sheet.year, sheet.month);
    return sheetDate.getTime() >= startDate.getTime() && sheetDate < endDate;
  } else {
    return true;
  }
}

// ---- READ ---- //

export async function fetchSheetsByClient(clientId: string) {
  try {
    const response = await getOrderedClientSheetsQuery(clientId).get();
    return response.docs?.map(getFormattedSheet);
  } catch (error) {
    console.error(error);
    return [];
  }
}

// exclusively for the useClientSheets hook
export async function fetchSheetsByClientAndDateRange(
  clientId: string,
  { startDate, endDate = new Date() }: TDateRange,
): Promise<ClientSheetI[]> {
  try {
    // 1. get client sheets that match the date range
    // TODO: improve it with firestore queries using month and year for date range (needs firebase update)
    const clientSheets: ClientSheetI[] = await fetchSheetsByClient(clientId);
    const clientSheetsWithinDates = clientSheets.filter(
      function onlyWithinDateRange(sheet: ClientSheetI) {
        return isSheetOnDateRange(sheet, { startDate, endDate });
      },
    );

    // 2. get editing and received sheets
    const pendingSheets = [
      ...clientSheets.filter(function onlyReceived(item) {
        return item.isSent && !item.isValidated; // all received
      }),
      ...clientSheetsWithinDates.filter(function onlyEditing(item) {
        return !item.isSent; // only curr period editing (users tend to have lots of editing sheets)
      }),
    ];

    // 3. get validated sheets from current period
    const validatedSheets = clientSheetsWithinDates.filter(
      function onlyFromThisPeriod(item: ClientSheetI) {
        const { isValidated, validationDate } = item;
        const wasValidatedThisMonth =
          validationDate &&
          validationDate.getTime() >=
            getDateStartingMonth(new Date()).getTime();
        const isSheetFromPrevMonth =
          getPrevMonthDate().getTime() ===
          new Date(item.year, item.month).getTime();
        return isValidated && (wasValidatedThisMonth || isSheetFromPrevMonth);
      },
    );

    return [...pendingSheets, ...validatedSheets];
  } catch (error) {
    console.error(error);
    return [];
  }
}
