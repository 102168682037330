export type TAuthCardName = 'login'; // change according to the need

type TAuthLink = { text: string; cardLink: TAuthCardName };
type TAuthField = {
  label: string;
  placeholder: string;
  type: string;
  required: boolean;
};
interface IAuthCard {
  title: string;
  subtitle: string;
  action?: string;
  fields?: { [name: string]: TAuthField };
  links?: TAuthLink[];
}

export type IAuthCards = {
  [name in TAuthCardName]: IAuthCard;
};

export const authCards: IAuthCards = {
  login: {
    title: 'Iniciar sesión',
    subtitle: 'Ingresa las credenciales para acceder a DataRips',
    action: 'Iniciar sesión',
    fields: {
      email: {
        label: 'Correo electrónico',
        placeholder: 'micorreo@email.com',
        type: 'email',
        required: true,
      },
      password: {
        label: 'Contraseña',
        placeholder: '*********',
        type: 'password',
        required: true,
      },
    },
  },
};

export enum authErrors {
  USER_NOT_ADMIN = 'user-not-admin',
  WRONG_PASSWORD = 'wrong-password',
  TOO_MANY_REQUESTS = 'too-many-requests',
  DEFAULT = 'default',
}

export const firebaseAuthToCustomError: { [errorCode: string]: string } = {
  'auth/user-not-found': authErrors.DEFAULT,
  'auth/wrong-password': authErrors.WRONG_PASSWORD,
  'auth/too-many-requests': authErrors.TOO_MANY_REQUESTS,
};

export const errorMessages: { [errorCode: string]: string } = {
  [authErrors.USER_NOT_ADMIN]:
    'Credenciales incorrectas. Revisa tu correo e intenta de nuevo.',
  [authErrors.WRONG_PASSWORD]:
    'No encontramos un usuario con este correo y contraseña.',
  [authErrors.TOO_MANY_REQUESTS]:
    'Parece que has hecho muchos intentos. Espera unos minutos e intenta de nuevo.',
  [authErrors.DEFAULT]:
    'Ha ocurrido un error. Por favor espera unos minutos e inténtalo de nuevo.',
};
