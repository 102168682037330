import styled from 'styled-components';
import { Card } from '@datarips/components';
import MONTHS from 'shared/constants/months';
import { SHEET_STATES } from '../constants';
import CardsSection from 'shared/components/organisms/CardsSection';
import { getCapitalizedString } from 'shared/utils/strings';
import { setClientSheetValidationState } from 'services/actions/clients';
import { HTMLAttributes } from 'react';

export type TKanbanCard = {
  id: string;
  title: string;
  month: number;
  year: number;
  state: SHEET_STATES[number];
  isPastDue: boolean;
  clientId: string;
};
type TSection = { title: string; cards: TKanbanCard[] };

function KanbanColumn({ title = '', cards = [] }: TSection) {
  cards.sort(function descByMonth(a, b) {
    return a.month < b.month ? 1 : -1;
  });
  const Cards = cards.map((card) => (
    <KanbanCard
      key={`kanban-card-${card.title}-${card.month}-${card.year}`}
      isPastDue={card.isPastDue}
      onClick={
        card.state === SHEET_STATES.Sent
          ? () => setClientSheetValidationState(card.clientId, card.id, true)
          : card.state === SHEET_STATES.Validated
          ? () => setClientSheetValidationState(card.clientId, card.id, false)
          : undefined
      }
    >
      <h5>{getCapitalizedString(card.title)}</h5>
      {MONTHS[card.month]} {String(card.year)}{' '}
      {card.isPastDue && <span className=".past-due-mark" />}
    </KanbanCard>
  ));

  return <CardsSection title={title} cards={Cards} cardsDirection="column" />;
}

const KanbanCard = styled(Card)<
  HTMLAttributes<HTMLDivElement> & { isPastDue: boolean }
>`
  h5 {
    margin: 0 0 4px 0;
    font-weight: 700;
    color: ${(props) => props.theme.textColor.main};
  }
  :after {
    color: #eb5757;
    content: '•';
    display: ${(props) => (props.isPastDue ? 'inline' : 'none')};
  }
  .badges {
    display: flex;
    gap: 5px;
  }
`;

export default KanbanColumn;
