import Header, { Divider } from 'shared/components/organisms/Header';
import RemainingDaysMessage from './RemainingDaysMessage';

interface IProps {
  currentDate: number;
  currentGroup: number;
  sheetsToValidate: number;
}

function getCurrentGroupDates(currentGroup: number) {
  const lastDateOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0,
  ).getDate();
  const endDate = Math.min(currentGroup * 10, lastDateOfMonth);
  const startDate = (currentGroup - 1) * 10 + 1;

  return { endDate, startDate };
}

function BoardHeader({ currentDate, currentGroup, sheetsToValidate }: IProps) {
  const { startDate, endDate } = getCurrentGroupDates(currentGroup);

  return (
    <Header>
      <div className="title">
        <h1>Planillas</h1>
        <Divider />
        <h4>
          Grupo {currentGroup} (días {startDate}-{endDate})
        </h4>
      </div>
      <div className="metadata">
        <span>
          <strong>{sheetsToValidate}</strong> planillas por validar
        </span>
        <Divider />
        <RemainingDaysMessage
          currentDate={currentDate}
          deadlineDate={endDate}
        />
      </div>
    </Header>
  );
}

export default BoardHeader;
