// ----------- DATES ----------- //

export function getDateStartingMonth(date: Date = new Date()) {
  // ignore times and days, use only year and month
  return new Date(date.getFullYear(), date.getMonth());
}

export function getPrevMonthDate(date: Date = new Date()) {
  const newDate = getDateStartingMonth(date);
  newDate.setDate(0); // 0 will result in the last day of the previous month
  newDate.setDate(1); // 1 will result in the first day of the month
  return newDate;
}
