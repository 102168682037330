import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Input } from '@datarips/components';

const CHARS =
  '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const PASSWORD_LENGTH = 6;

function generatePassword() {
  let password = '';
  for (var i = 0; i <= PASSWORD_LENGTH; i++) {
    const randomNumber = Math.floor(Math.random() * CHARS.length);
    password += CHARS.substring(randomNumber, randomNumber + 1);
  }
  return password;
}

export default function PasswordGeneratorInput({
  name,
  onChange,
}: {
  name: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
}) {
  const [input, setInput] = useState('');

  useEffect(() => {
    setInput(generatePassword());
  }, []);

  useEffect(() => {
    onChange({
      target: { name, value: input },
    } as ChangeEvent<HTMLInputElement>);
  }, [input]);

  return (
    <StyledInput>
      <Input
        label="Contraseña *"
        placeholder="Crear contraseña"
        onChange={(e) => setInput(e.target.value)}
        value={input}
      />
      <Button
        type="button"
        onlyIcon
        icon="arrow-counter-clockwise"
        onClick={() => setInput(generatePassword())}
      />
    </StyledInput>
  );
}

const StyledInput = styled.div`
  display: flex;
  align-items: flex-end;
`;
