import {
  DEFAULT_SPECIALIZATION,
  SPECIALIZATIONS,
} from 'shared/constants/clients';

export function getSpecializationText(specializationValue: string): string {
  return (
    SPECIALIZATIONS.find(function hasSameValue(specialization) {
      return specialization.value === specializationValue;
    })?.text || DEFAULT_SPECIALIZATION
  );
}
